import { DialogTitle } from "@headlessui/react";
import { useStore } from "@nanostores/react";
import { useCallback, useRef, useState, type ReactElement } from "react";
import { $isFeedbackOpen } from "stores/feedback";
import { notify } from "stores/notification";
import Button from "./ui/Button";
import Dialog from "./ui/Dialog";
import Input from "./ui/Input";

interface Props {
  close?: ReactElement;
}

export default function Feedback({ close }: Props) {
  const open = useStore($isFeedbackOpen);
  const [text, setText] = useState("");
  const isSubmittingRef = useRef(false);

  const onClose = useCallback(() => {
    setText("");
    $isFeedbackOpen.set(false);
  }, []);

  const onSubmit = useCallback(async () => {
    if (isSubmittingRef.current) {
      return;
    }

    isSubmittingRef.current = true;

    onClose();

    await fetch("/api/feedback", {
      method: "POST",
      body: JSON.stringify({ text }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    isSubmittingRef.current = false;
    notify({ title: "Thank you, we have received your feedback!" });
  }, [text]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-white p-6">
        <div className="absolute right-0 top-0 pr-6 pt-6">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose}>
            <span className="sr-only">Close</span>
            {close}
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="text-left w-full">
            <DialogTitle
              as="h3"
              className="text-lg font-semibold leading-6 text-gray-900">
              Any specific need or feedback?
            </DialogTitle>
            <div className="mt-1 mb-5">
              <p className="text-sm text-gray-600">
                Let us understand your problem so we can help you better.
              </p>
            </div>
            <Input
              placeholder="e.g. I want to be able to quickly check all the windows of buildings with more than 15 floors in the Miami area..."
              block
              name="feedback"
              textarea
              rows={4}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
              <Button style="muted" className="text-gray-600" onClick={onClose}>
                Cancel
              </Button>
              <Button
                style="primary"
                type="button"
                onClick={onSubmit}
                disabled={text.length === 0}>
                Send feedback
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
